import EnCours from './EnCours';
import Agence from './composants/agence/Agence';
import Contact from './composants/contact/Contact';
import Footer from './composants/footer/footer';
import Header from './composants/header/header';
import Parrainage from './composants/parrainage/Parrainage';
import Services from './composants/prestations/Services';


function App() {
  return (
    <div>

      <EnCours/>

      {/* <Header/>
      <Agence/>
      <Services/>
      <Contact/>
      <Parrainage/>
      <Footer/> */}

    </div>
  );
}

export default App;