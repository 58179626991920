import React from 'react'

export default function EnCours() {
  return (
    <div className='h-screen flex flex-col items-center justify-center'>
      <i class="fas fa-tools text-6xl"></i>
      <span className='mt-6 text-2xl'>PAGE EN COURS DE MAINTENANCE</span>
    </div>
  )
}
